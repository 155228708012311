<template>
  <va-card :title="$t('reports.resume.country')">
    <template v-slot:actions>
      <actions
        crud-links="reports"
        controller="Proyections"
        :actions="actions"
        :action-data="{}"
      >
        <template v-slot:before>
          <va-badge
            :color="report.approval === 1 ? 'success' : 'danger'"
            v-show="report.approval === 1 || report.approval === -1"
          >
            {{ $t(approvalLabel(report.approval)) }}
          </va-badge>
        </template>
        <template
          v-if="report.id != null && currentUser.can('Proyections', 'monthly')"
        >
          <va-popover
            :message="$t('tables.actions.export', { format: 'Excel' })"
            placement="left"
          >
            <va-button
              flat
              small
              color="success"
              icon="fa fa-file-excel-o"
              @click.prevent="prepareDownload()"
            />
          </va-popover>
        </template>
      </actions>
    </template>
    <tabs-container :tabs="tabs">
      <template v-slot:reports>
        <div class="row">
          <div
            class="flex xs12"
            v-show="Number.isFinite(totals.reports.presentations)"
          >
            <h5>
              {{
                $t("reports.resume.total_presentations", {
                  total: totals.reports.presentations || 0,
                })
              }}
            </h5>
            <h5>
              {{
                $t("reports.resume.total_audience", {
                  total: totals.reports.audience || 0,
                })
              }}
            </h5>
            <h5>
              {{
                $t("reports.resume.total_newcomers", {
                  total: totals.reports.newcomers || 0,
                })
              }}
            </h5>
            <h5>
              {{
                $t("reports.resume.total_regulars", {
                  total: totals.reports.regulars || 0,
                })
              }}
            </h5>
            <h5>
              {{
                $t("reports.resume.total_preaching_points", {
                  total: totals.reports.preaching_points || 0,
                })
              }}
            </h5>
            <h5>
              {{
                $t("reports.resume.total_preaching_assistance", {
                  total: totals.reports.assistance || 0,
                })
              }}
            </h5>
          </div>
          <div class="flex xs12">
            <local-table
              class="table-hover table-striped full-table"
              crud-links="reports"
              :columns="resumeFields"
              :data="tableData.resume"
              :editable="edit"
              :loading="loading"
              :top-buttons="[]"
              :row-style-class="totalRowClass"
            />
          </div>
        </div>
      </template>
      <template v-slot:testimonies>
        <local-table
          class="table-hover table-striped full-table"
          :columns="testimoniesFields"
          :data="tableData.testimonies"
          :loading="loading"
          :top-buttons="[]"
        />
      </template>
      <template v-slot:assignments>
        <div class="row">
          <div
            class="flex xs12"
            v-show="Number.isFinite(totals.assignment.total)"
          >
            <h5>
              {{
                $t("reports.resume.country_assignment", {
                  assignment: totals.assignment.country_assignment || 0,
                })
              }}
            </h5>
            <h5>
              {{
                $t("reports.resume.team_assignment", {
                  assignment: totals.assignment.team_assignment || 0,
                })
              }}
            </h5>
            <h5>
              {{
                $t("reports.resume.total_assignment", {
                  assignment: totals.assignment.total || 0,
                })
              }}
            </h5>
          </div>
          <div class="flex xs12">
            <local-table
              class="table-hover table-striped full-table"
              :columns="assignmentsFields"
              :data="tableData.assignments"
              :loading="loading"
              :top-buttons="[]"
              :row-style-class="totalRowClass"
            />
          </div>
        </div>
      </template>
    </tabs-container>
  </va-card>
</template>
<script>
import { mapGetters } from "vuex";
const LocalTable = () =>
  import(
    /* webpackPrefetch: true */ "@/components/extras/DataTables/LocalTable"
  );
const TabsContainer = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/TabsContainer");
const Actions = () =>
  import(/* webpackPrefetch: true */ "@/components/extras/Actions");

export default {
  name: "country-report-table",
  components: {
    TabsContainer,
    LocalTable,
    Actions,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    report: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    tabs() {
      return [
        { title: this.$t("reports.resume.reports"), name: "reports" },
        { title: this.$t("reports.resume.testimonies"), name: "testimonies" },
        { title: this.$t("reports.resume.assignments"), name: "assignments" },
      ];
    },
    canReview() {
      return this.currentUser.can("CountryReports", "approve");
    },
    resumeFields() {
      return [
        {
          name: "code",
          title: this.$t("tables.headings.team"),
          sortField: "code",
        },
        {
          name: "date",
          title: this.$t("tables.headings.date"),
          callback: this.dateLabel,
          sortField: "date",
        },
        {
          name: "presentations",
          title: this.$t("tables.headings.presentations"),
        },
        {
          name: "audience",
          title: this.$t("tables.headings.assistance"),
        },
        {
          name: "newcomers",
          title: this.$t("tables.headings.newcomers"),
        },
        {
          name: "regulars",
          title: this.$t("tables.headings.regulars"),
        },
        {
          name: "preaching_points",
          title: this.$t("tables.headings.preaching_points"),
        },
        {
          name: "assistance",
          title: this.$t("tables.headings.preaching_assistance"),
        },
        {
          name: "location",
          title: this.$t("tables.headings.location"),
        },
        {
          name: "district",
          title: this.$t("tables.headings.district"),
          callback: this.translatedName,
        },
        {
          name: "minister",
          title: this.$t("tables.headings.minister"),
        },
        {
          name: "completed",
          title: this.$t("tables.headings.complete_report"),
          callback: this.completedLabel,
        },
        {
          name: "actions",
          visible: this.edit,
        },
      ];
    },
    testimoniesFields() {
      return [
        {
          name: "code",
          title: this.$t("tables.headings.team"),
          sortField: "code",
          width: "10%",
        },
        {
          name: "original",
          title: this.$t("tables.headings.testimony_original"),
          dataClass: this.hasTestimonyEsClass,
          width: "45%",
        },
        {
          name: "english",
          title: this.$t("tables.headings.testimony_english"),
          dataClass: this.hasTestimonyEnClass,
          width: "45%",
        },
      ];
    },
    assignmentsFields() {
      return [
        {
          name: "code",
          title: this.$t("tables.headings.team"),
          sortField: "code",
        },
        {
          name: "approval",
          title: this.$t("tables.headings.approved_report"),
          callback: this.approvalLabel,
        },
        {
          name: "country_assignment",
          title: this.$t("tables.headings.country_assignment"),
          callback: this.assignmentsLabel,
        },
        {
          name: "team_assignment",
          title: this.$t("tables.headings.team_assignment"),
          callback: this.assignmentsLabel,
        },
      ];
    },
  },
  data() {
    return {
      actions: ["index"],
      tableData: {
        resume: [],
        testimonies: [],
        assignments: [],
      },
      totals: {
        reports: {},
        assignment: {},
      },
    };
  },
  watch: {
    async report(val) {
      if (val.resume) {

        var i = 0;
        for (var key of val.proyections) {
          var asist = 0;
          if (key.preaching_points.length > 0) {
            val.resume[i].location = key.preaching_points[0].name;
            for (var data of key.preaching_points) {
              asist += data.assistance_adults;
              asist += data.assistance_kids;
              asist += data.assistance_youngs;

              val.resume[i].assistance = asist;
            }
          } else {
            val.resume[i].assistance = asist;
          }
          i++;
        }
        this.tableData.resume = val.resume;
      }
      if (val.testimonies) {
        this.tableData.testimonies = val.testimonies;
      }
      if (val.assignments) {
        this.tableData.assignments = val.assignments;
      }
      if (val.totals) {
        this.totals = Object.assign({}, val.totals);
      }
    },
  },
  methods: {
    translatedName(name) {
      return this.$t(name);
    },
    approvalLabel(approval) {
      let a = "tables.status.unreviewed";
      switch (approval) {
        case 1:
          a = "tables.status.approved";
          break;
        case -1:
          a = "tables.status.rejected";
          break;
      }

      return this.$t(a);
    },
    completedLabel(completed) {
      if (completed === "") return "";
      const a = completed ? "layout.yes" : "layout.no";
      return this.$t(a);
    },
    dateLabel(date) {
      if (date === "") return "";
      return this.$date.format(date, "MMMM - yyyy");
    },
    assignmentsLabel(assignment) {
      return "$" + assignment;
    },
    hasTestimonyEsClass(row) {
      return this.hasTestimonyClass(row.original);
    },
    hasTestimonyEnClass(row) {
      return this.hasTestimonyClass(row.english);
    },
    hasTestimonyClass(testimony) {
      return testimony && testimony.length > 0
        ? "has-testimony"
        : "non-testimony";
    },
    totalActionsClass(row) {
      return row.country === "TOTAL" ? "total-actions" : "";
    },
    totalRowClass(row) {
      return row.code === "TOTAL" ? "total-row" : "";
    },
    async prepareDownload() {
      const d = {
        type: "country",
        date: this.report.proyection_date,
        id: this.report.country_id,
      };

      const prepareRoute = "proyections/monthly?export=xlsx";
      const downloadRoute = "proyections/download/";

      return this.downloadFile(d, prepareRoute, downloadRoute);
    },
    async downloadFile(filters, prepareRoute, downloadRoute) {
      let fileName = "";
      const prepare = {
        icon: "info",
        title: this.$t("notifications.download.prepare"),
        confirmButtonText: this.$t("notifications.download.button"),
        text: this.$t("notifications.download.wait"),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false;
          try {
            data = await this.$http.post(prepareRoute, filters);
          } catch (e) {
            this.$swal.insertQueueStep(error);
            return;
          }

          fileName = data.data.data;
          this.$swal.insertQueueStep(download);
        },
      };
      const error = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.error"),
      };
      const download = {
        icon: "success",
        title: this.$t("notifications.download.downloading"),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading();
          let response = false;
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: "blob",
            });
          } catch (e) {
            await this.$swal.queue([retry]);
            return;
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.$swal.close();
        },
      };
      const retry = {
        icon: "error",
        title: "Error",
        text: this.$t("notifications.download.failed"),
        confirmButtonText: this.$t("notifications.download.retry"),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download);
        },
      };

      this.$swal.queue([prepare]);
    },
  },
};
</script>
<style lang="scss">
.report-title {
  margin-bottom: 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

.full-table {
  width: 100%;
}

.has-testimony {
  background-color: rgba(0, 255, 0, 0.25);
}

.non-testimony {
  background-color: rgba(255, 0, 0, 0.25);
}

.total-row {
  font-size: bold;

  span {
    color: black;
  }
}

.total-actions {
  span {
    display: none;
  }
}
</style>
